var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2 mb-1"},[_c('div',[(_vm.previewMode)?_c('h4',[_vm._v("Title: "+_vm._s(_vm.input.title))]):_c('a-input',{staticStyle:{"width":"250px"},model:{value:(_vm.input.title),callback:function ($$v) {_vm.$set(_vm.input, "title", $$v)},expression:"input.title"}}),(_vm.mode !== 'view' && !_vm.previewMode)?_c('a-button',{staticClass:"btn btn-outline-primary ml-2",on:{"click":_vm.saveDocumentation}},[_vm._v(_vm._s(_vm.mode == "create" ? "Save documentation" : "Save changes"))]):_vm._e(),(_vm.mode !== 'view' && !_vm.previewMode)?_c('a-button',{staticClass:"btn btn-outline-success ml-2",on:{"click":function($event){_vm.cheatsheetmodalvisible = true}}},[_vm._v("Cheat sheet")]):_vm._e()],1)]),_c('div',{class:!_vm.previewMode ? 'col-xs-5 col-sm-5 col-md-5 col-lg-5' : 'd-none'},[_c('textarea',{staticStyle:{"width":"100%"},attrs:{"rows":"20"},domProps:{"value":_vm.input.content},on:{"input":_vm.update}})]),_c('div',{class:!_vm.previewMode
          ? 'col-xs-7 col-sm-7 col-md-7 col-lg-7'
          : 'col-xs-9 col-sm-9 col-md-9 col-lg-9'},[_c('vue-markdown',{key:_vm.componentKey,attrs:{"html":true,"linkify":true,"toc":true,"source":_vm.input.content,"show":true,"breaks":true,"emoji":true,"typographer":true,"toc-id":"toc"},on:{"rendered":_vm.allRight,"toc-rendered":_vm.tocAllRight}})],1),_c('div',{class:!_vm.previewMode ? 'd-none' : '',staticStyle:{"border-right":"1px solid","position":"fixed","width":"65%","top":"70px"}},[_c('div',{staticStyle:{"position":"absolute","top":"30px","right":"1px","max-width":"80%","y-overflow":"scroll"},attrs:{"id":"toc"}})])]),_c('a-modal',{attrs:{"title":"Cheat Sheet Markdown","dialogStyle":{ top: '10px' },"width":700,"footer":null},model:{value:(_vm.cheatsheetmodalvisible),callback:function ($$v) {_vm.cheatsheetmodalvisible=$$v},expression:"cheatsheetmodalvisible"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12"},[_c('a-table',{staticStyle:{"margin-left":"-24px","margin-right":"-24px","margin-top":"-25px"},attrs:{"columns":_vm.cheatsheetmodal.columns,"dataSource":_vm.cheatsheetmodal.datatable,"size":"small","pagination":{
            defaultPageSize: 1000000,
            hideOnSinglePage: true,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            pageSizeOptions: ['10', '20', '30'],
          }},scopedSlots:_vm._u([{key:"filterDropdown",fn:function({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }){return _c('div',{staticStyle:{"padding":"8px"}},[_c('a-input',{directives:[{name:"ant-ref",rawName:"v-ant-ref",value:((c) => (_vm.searchInput = c)),expression:"(c) => (searchInput = c)"}],staticStyle:{"width":"188px","margin-bottom":"8px","display":"block"},attrs:{"placeholder":`Search ${column.dataIndex}`,"value":selectedKeys[0]},on:{"change":(e) => setSelectedKeys(e.target.value ? [e.target.value] : []),"pressEnter":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}}),_c('a-button',{staticStyle:{"width":"90px","margin-right":"8px"},attrs:{"type":"primary","icon":"search","size":"small"},on:{"click":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}},[_vm._v(" Search ")]),_c('a-button',{staticStyle:{"width":"90px"},attrs:{"size":"small"},on:{"click":() => _vm.handleReset(clearFilters)}},[_vm._v(" Reset ")])],1)}},{key:"filterIcon",fn:function(filtered){return _c('a-icon',{style:({ color: filtered ? '#108ee9' : undefined }),attrs:{"type":"search"}})}},{key:"customRender",fn:function(text, record, index, column){return [(_vm.searchText && _vm.searchedColumn === column.dataIndex)?_c('span',[_vm._l((text
                  .toString()
                  .split(
                    new RegExp(`(?<=${_vm.searchText})|(?=${_vm.searchText})`, 'i')
                  )),function(fragment,i){return [(fragment.toLowerCase() === _vm.searchText.toLowerCase())?_c('mark',{key:i,staticClass:"highlight"},[_vm._v(_vm._s(fragment))]):[_vm._v(_vm._s(fragment))]]})],2):[(column.dataIndex === 'name')?_c('span',[_vm._v(" "+_vm._s(text)+" ")]):_c('span',[_vm._v(" "+_vm._s(text)+" ")])]]}},{key:"syntax",fn:function(text){return [_c('div',{domProps:{"innerHTML":_vm._s(text)}})]}}])})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }