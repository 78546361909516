<template>
  <div>
    <div class="row">
      <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" v-if="user.isHidden"> -->
        <!-- <label
          style="cursor: pointer; text-decoration: underline;"
          class="text-danger"
          @click="backToIndex"
          ><a-icon type="caret-left" /> Back</label
        > -->
        <!-- <label class="ml-4">Preview Mode: </label>
        <a-switch class="ml-2" v-model="previewMode" @change="componentKey++">
          <a-icon slot="checkedChildren" type="check" />
          <a-icon slot="unCheckedChildren" type="close" />
        </a-switch> -->
      <!-- </div> -->
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2 mb-1">
        <!-- <label
          style="cursor: pointer; text-decoration: underline;"
          class="text-danger"
          @click="backToIndex"
          ><a-icon type="caret-left" /> Back</label
        > -->
        <div>
          <h4 v-if="previewMode">Title: {{ input.title }}</h4>
          <a-input
            class=""
            v-model="input.title"
            style="width: 250px;"
            v-else
          ></a-input>
          <a-button
            v-if="mode !== 'view' && !previewMode"
            class="btn btn-outline-primary ml-2"
            @click="saveDocumentation"
            >{{
              mode == "create" ? "Save documentation" : "Save changes"
            }}</a-button
          >
          <a-button
            v-if="mode !== 'view' && !previewMode"
            class="btn btn-outline-success ml-2"
            @click="cheatsheetmodalvisible = true"
            >Cheat sheet</a-button
          >
        </div>
      </div>
      <div
        :class="!previewMode ? 'col-xs-5 col-sm-5 col-md-5 col-lg-5' : 'd-none'"
      >
        <textarea
          :value="input.content"
          @input="update"
          rows="20"
          style="width: 100%;"
        ></textarea>
      </div>
      <div
        :class="
          !previewMode
            ? 'col-xs-7 col-sm-7 col-md-7 col-lg-7'
            : 'col-xs-9 col-sm-9 col-md-9 col-lg-9'
        "
      >
        <vue-markdown
          :key="componentKey"
          :html="true"
          :linkify="true"
          :toc="true"
          :source="input.content"
          :show="true"
          :breaks="true"
          :emoji="true"
          :typographer="true"
          v-on:rendered="allRight"
          v-on:toc-rendered="tocAllRight"
          toc-id="toc"
        ></vue-markdown>
        <!-- <markdown-it-toc class="markdown-it-wrap"
          :content="input"
          :offset="40"
          :level="4">
        </markdown-it-toc> -->
        <!-- <vue-marked :marked="marked" :key="componentKey">{{
          input
        }}</vue-marked> -->
      </div>
      <div
        :class="!previewMode ? 'd-none' : ''"
        style="border-right: 1px solid; position: fixed; width: 65%; top: 70px;"
      >
        <!-- <label
          style="
            position: absolute;
            right: 9%;
            cursor: pointer;
            text-decoration: underline;
          "
          class="text-danger"
          @click="backToIndex"
          ><a-icon type="caret-left" /> Back to index</label
        > -->
        <div
          id="toc"
          style="
            position: absolute;
            top: 30px;
            right: 1px;
            max-width: 80%;
            y-overflow: scroll;
          "
        ></div>
      </div>
    </div>
    <!-- <div v-html="compiledMarkdown"></div> -->
    <a-modal
      title="Cheat Sheet Markdown"
      v-model="cheatsheetmodalvisible"
      :dialogStyle="{ top: '10px' }"
      :width="700"
      :footer="null"
    >
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <a-table
            :columns="cheatsheetmodal.columns"
            :dataSource="cheatsheetmodal.datatable"
            size="small"
            style="
              margin-left: -24px;
              margin-right: -24px;
              margin-top: -25px;
            "
            :pagination="{
              defaultPageSize: 1000000,
              hideOnSinglePage: true,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['10', '20', '30'],
            }"
          >
            <div
              slot="filterDropdown"
              slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              style="padding: 8px;"
            >
              <a-input
                v-ant-ref="(c) => (searchInput = c)"
                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px;"
                @click="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              >
                Search
              </a-button>
              <a-button
                size="small"
                style="width: 90px;"
                @click="() => handleReset(clearFilters)"
              >
                Reset
              </a-button>
            </div>
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            />
            <template
              slot="customRender"
              slot-scope="text, record, index, column"
            >
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(
                      new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                    )"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
                    >{{ fragment }}</mark
                  >
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>
                <span v-if="column.dataIndex === 'name'">
                  {{ text }}
                </span>
                <span v-else>
                  {{ text }}
                </span>
              </template>
            </template>
            <template slot="syntax" slot-scope="text">
              <div v-html="text"></div>
            </template>
          </a-table>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import debounce from 'lodash/debounce'
import imarked from 'marked'
// import VueMarked from '@hrwg/vue-marked'
import * as lou from '@/services/data/lou'
// import hljs from 'highlight.js'
import VueMarkdown from 'vue-markdown'

export default {
  name: 'mymarkdowncomponent',
  components: {
    // VueMarked,
    VueMarkdown,
  },
  data: function () {
    return {
      mode: 'view',
      previewMode: true,
      listToc: '',
      actualData: {},
      titleInputMode: false,
      input: {
        title: '',
        content: '',
      },
      options: {
        markdownIt: {
          linkify: true,
        },
        linkAttributes: {
          attrs: {
            target: '_blank',
            rel: 'noopener',
          },
        },
      },
      cheatsheetmodalvisible: false,
      cheatsheetmodal: {
        columns: [
          {
            title: 'Element',
            dataIndex: 'element',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) =>
              record.element
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Markdown Syntax',
            dataIndex: 'syntax',
            scopedSlots: {
              customRender: 'syntax',
            },
          },
        ],
        datatable: [
          {
            element: 'Heading',
            syntax: '# H1 <br><br> ## H2 <br><br> ###H3',
          },
          {
            element: 'Bold',
            syntax: '**bold text**',
          },
          {
            element: 'Italic',
            syntax: '*italicized text*',
          },
          {
            element: 'Blockquote',
            syntax: '> blockquote',
          },
          {
            element: 'Ordered List',
            syntax: '1. First item <br><br>  2. Second Item <br><br>  3. Second Item',
          },
          {
            element: 'Unordered List',
            syntax: '- First item <br><br>  - Second Item <br><br>  - Second Item',
          },
          {
            element: 'Code',
            syntax: '`code`',
          },
          {
            element: 'Horizontal Rule (Divider)',
            syntax: '---',
          },
          {
            element: 'Link',
            syntax: '[title](https://www.example.com)',
          },
          {
            element: 'Image',
            syntax: '![alt text](image.jpg)',
          },
          {
            element: 'Table',
            syntax: '| Syntax | Description |<br><br>| ----------- | ----------- |<br><br>| Header | Title |<br><br>| Paragraph | Text |',
          },
          {
            element: 'Fenced Code Block',
            syntax: '```<br><br>{<br><br>"firstName": "John",<br><br>"lastName": "Smith",<br><br>}<br><br>```',
          },
          {
            element: 'Footnote',
            syntax: 'Here\'s a sentence with a footnote. [^1]<br><br>[^1]: This is the footnote.',
          },
          {
            element: 'Heading ID',
            syntax: '### My Great Heading {#custom-id}',
          },
          {
            element: 'Difinition List',
            syntax: 'term<br><br>: definition',
          },
          {
            element: 'Strikethrough',
            syntax: '~~The world is round.~~',
          },
          {
            element: 'Task List',
            syntax: '- [x] Write the press release<br><br>- [ ] Update the website<br><br>- [ ] Contact the media',
          },
          {
            element: 'Emoji',
            syntax: 'That is so funny! :joy:',
          },
          {
            element: 'Highlight',
            syntax: 'I need to highlight these ==very important words==.',
          },
          {
            element: 'Subscript',
            syntax: 'H~2~O',
          },
          {
            element: 'Superscript',
            syntax: 'X^2^',
          },
        ],
      },
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      componentKey: 0,
    }
  },
  watch: {
    previewMode(newValue, oldValue) {
      console.log('newValue', newValue)
      console.log('oldValue', oldValue)
    },
  },
  created() {
    this.marked = imarked
    // if (this.user.isHidden) {
    //   this.previewMode = false
    // }
    // if (this.markdown !== '') {
    //   this.input.content = this.markdown
    // }
    // this.marked.setOptions({
    //   sanitize: true,
    // })
  },
  computed: {
    ...mapState(['user']),
    //   compiledMarkdown() {
    //     // console.log('Input: ', this.input)
    //     return marked(this.input, { sanitize: true })
    //   },
  },
  methods: {
    setTheMarkdown(action, record) {
      this.mode = action
      if (action === 'view') {
        this.previewMode = true
        this.actualData = record
        this.input = record
      } else {
        if (action === 'create') {
          this.titleInputMode = true
          this.previewMode = false
          this.actualData = {}
          this.input = {
            title: '',
            content: '',
          }
        } else {
          this.titleInputMode = true
          this.previewMode = false
          this.actualData = record
          this.input = record
        }
        this.previewMode = false
      }
      // console.log('this.previewMode', this.previewMode)
      this.componentKey++
    },
    backToIndex() {
      // console.log('this.$parent', this.$parent)
      this.$parent.onviewpage = false
      this.input = {
        title: '',
        content: '',
      }
      this.componentKey = 0
      this.$parent.getAllData()
    },
    update: debounce(function (e) {
      // console.log('e.target.value', e.target.value)
      this.input.content = e.target.value
      this.componentKey++
    }, 300),
    allRight: function (htmlStr) {
      // console.log('markdown is parsed !')
    },
    tocAllRight: function (tocHtmlStr) {
      // console.log('toc is parsed :', tocHtmlStr)
      this.listToc = tocHtmlStr
    },
    async saveDocumentation() {
      var res = []
      if (this.mode === 'create') {
        var fd = {
          title: this.input.title,
          content: this.input.content,
          id: 0,
          app_id: 0,
        }
        res = await lou.customUrlPost('doc', fd, true, true, true)
        if (!res.isError) {
          this.backToIndex()
          // lou.shownotif('Success', 'Dokumentasi telah berhasil tersimpan!', 'success')
        }
      } else if (this.mode === 'update') {
        var fd1 = {
          title: this.input.title,
          content: this.input.content,
          id: this.input.id,
          app_id: 0,
        }
        res = await lou.customUrlPut('doc', fd1, true, true, true)
        if (!res.isError) {
          this.backToIndex()
          // lou.shownotif('Success', 'Dokumentasi telah berhasil tersimpan!', 'success')
        }
      } else {
        lou.shownotif(
          'Error!',
          'Tidak ada mode ' + this.mode + ' dalam list action!',
          'error',
        )
      }
      // var target = this.$parent.datatable.findIndex(x => x.id === this.actualData.id)
      // if (target >= 0) {
      //   this.$parent.datatable[target].markdown = this.input
      //   this.$parent.onviewpage = false
      // }
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
  },
}
</script>

<style lang="scss" scoped>
html,
body,
#editor {
  margin: 0;
  height: 100%;
  font-family: "Helvetica Neue", Arial, sans-serif;
  color: #333;
}

textarea,
#editor div {
  display: inline-block;
  width: 49%;
  height: 100%;
  vertical-align: top;
  box-sizing: border-box;
  padding: 0 20px;
}

textarea {
  border: none;
  border-right: 1px solid #ccc;
  resize: none;
  outline: none;
  background-color: #f6f6f6;
  font-size: 14px;
  font-family: "Monaco", courier, monospace;
  padding: 20px;
}

code {
  color: #f66;
  background-color: silver;
  padding: 10px;
}
</style>
