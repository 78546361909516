<template>
  <div>
    <div>
      <div class="row">
        <div
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
          v-if="user.isHidden"
        >
          <!-- <label
          style="cursor: pointer; text-decoration: underline;"
          class="text-danger"
          @click="backToIndex"
          ><a-icon type="caret-left" /> Back</label
        > -->
          <label class="">Preview Mode: </label>
          <a-switch
            class="ml-2"
            v-model="onviewpage"
            @change="onViewPageChange"
          >
            <a-icon slot="checkedChildren" type="check" />
            <a-icon slot="unCheckedChildren" type="close" />
          </a-switch>
        </div>
        <div
          :class="
            adminMode
              ? 'col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2'
              : 'col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2'
          "
        >
          <a-card>
            <template slot="title">
              <a-button
                class="btn btn-outline-primary"
                v-if="adminMode && !onviewpage"
                @click="setMarkdown('create', { id: null })"
                ><a-icon type="plus" /> Add new documentation</a-button
              >
            </template>
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <a-table
                  v-if="adminMode && !onviewpage"
                  :columns="columns"
                  :dataSource="datatable"
                  :key="componentKey"
                  size="small"
                  :scroll="{ y: 500 }"
                  :pagination="{
                    defaultPageSize: 1000000,
                    hideOnSinglePage: true,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    showTotal: (total) => `Total ${total} items`,
                    pageSizeOptions: ['10', '20', '30'],
                  }"
                >
                  <div
                    slot="filterDropdown"
                    slot-scope="{
                      setSelectedKeys,
                      selectedKeys,
                      confirm,
                      clearFilters,
                      column,
                    }"
                    style="padding: 8px;"
                  >
                    <a-input
                      v-ant-ref="(c) => (searchInput = c)"
                      :placeholder="`Search ${column.dataIndex}`"
                      :value="selectedKeys[0]"
                      style="width: 188px; margin-bottom: 8px; display: block;"
                      @change="
                        (e) =>
                          setSelectedKeys(
                            e.target.value ? [e.target.value] : []
                          )
                      "
                      @pressEnter="
                        () =>
                          handleSearch(selectedKeys, confirm, column.dataIndex)
                      "
                    />
                    <a-button
                      type="primary"
                      icon="search"
                      size="small"
                      style="width: 90px; margin-right: 8px;"
                      @click="
                        () =>
                          handleSearch(selectedKeys, confirm, column.dataIndex)
                      "
                    >
                      Search
                    </a-button>
                    <a-button
                      size="small"
                      style="width: 90px;"
                      @click="() => handleReset(clearFilters)"
                    >
                      Reset
                    </a-button>
                  </div>
                  <a-icon
                    slot="filterIcon"
                    slot-scope="filtered"
                    type="search"
                    :style="{ color: filtered ? '#108ee9' : undefined }"
                  />
                  <template
                    slot="customRender"
                    slot-scope="text, record, index, column"
                  >
                    <span
                      v-if="searchText && searchedColumn === column.dataIndex"
                    >
                      <template
                        v-for="(fragment, i) in text
                          .toString()
                          .split(
                            new RegExp(
                              `(?<=${searchText})|(?=${searchText})`,
                              'i'
                            )
                          )"
                      >
                        <mark
                          v-if="
                            fragment.toLowerCase() === searchText.toLowerCase()
                          "
                          :key="i"
                          class="highlight"
                          >{{ fragment }}</mark
                        >
                        <template v-else>{{ fragment }}</template>
                      </template>
                    </span>
                    <template v-else>
                      <span v-if="column.dataIndex === 'name'">
                        {{ text }}
                      </span>
                      <span v-else>
                        {{ text }}
                      </span>
                    </template>
                  </template>
                  <template slot="actions" slot-scope="text, record">
                    <a-icon
                      type="eye"
                      class="text-success"
                      @click="setMarkdown('view', record)"
                    />
                    <a-divider type="vertical"></a-divider>
                    <a-icon
                      type="edit"
                      class="text-warning"
                      @click="setMarkdown('update', record)"
                    />
                    <a-divider type="vertical"></a-divider>
                    <a-icon
                      type="delete"
                      class="text-danger"
                      @click="deleteDocumentation('delete', record)"
                    />
                  </template>
                </a-table>
                <a-tree
                  v-else
                  style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                  :show-line="true"
                  :tree-data="pageList"
                  :disabled="oninputmode"
                  @select="onSelectTree"
                >
                </a-tree>
              </div>
            </div>
          </a-card>
        </div>
        <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10" v-if="onviewpage">
          <backupcode ref="mycontentcomponent" :key="componentKey" />
        </div>
      </div>
    </div>
    <a-modal
      title="Delete documentation. Are you ABSOLUTELY SURE?"
      v-model="modalDelete.visible"
    >
      <template slot="footer">
        <a-button
          class="btn btn-outline-danger"
          @click="modalDelete.visible = false"
          >No, Keep documentation</a-button
        >
        <a-button
          class="btn btn-outline-primary"
          @click="deleteDocumentation(false, modalDelete.data)"
          >Yes, Delete documentation</a-button
        >
      </template>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <label style="margin: unset;"
            >Please type the following to confirm:</label
          >
          <br />
          <label style="margin: unset; background-color: #d3d3d3;"
            >Documentation/{{ modalDelete.data.title }}</label
          >
          <a-input class="mt-2" v-model="modalDelete.captha"></a-input>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import * as lou from '@/services/data/lou'
import backupcode from './backupcode'
import moment from 'moment'
export default {
  components: {
    backupcode,
  },
  created() {
    if (this.user.isHidden) {
      this.adminMode = true
    }
    this.getAllData()
  },
  computed: {
    ...mapState(['user', 'settings']),
    //   compiledMarkdown() {
    //     // console.log('Input: ', this.input)
    //     return marked(this.input, { sanitize: true })
    //   },
  },
  data() {
    return {
      oninputmode: false,
      onviewpage: false,
      adminMode: false,
      pageList: [],
      modalDelete: {
        visible: false,
        captha: '',
        data: {},
      },
      columns: [
        {
          title: 'Aksi',
          width: 100,
          ellipsis: true,
          scopedSlots: { customRender: 'actions' },
        },
        {
          title: 'Page Name',
          dataIndex: 'title',
          width: 250,
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.title.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Content',
          dataIndex: 'content',
          ellipsis: true,
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.content
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
      ],
      datatable: [],
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      componentKey: 0,
    }
  },
  methods: {
    moment,
    onViewPageChange(e) {
      var checked = e
      // console.log('checked', checked)
      if (checked) {
        if (this.datatable.length !== 0) {
          this.adminMode = false
          // this.onviewpage = true
          this.oninputmode = false
          this.componentKey++
          setTimeout(() => {
            this.setMarkdown('view', this.datatable[0])
          }, 50)
        }
      } else {
        this.adminMode = true
        // this.onviewpage = false
        this.componentKey++
      }
    },
    setMarkdown(action, record) {
      this.adminMode = false
      this.onviewpage = true
      if (action === 'create' || action === 'update') {
        this.oninputmode = true
      }
      // console.log('this.$refs', this.$refs)
      setTimeout(() => {
        this.$refs.mycontentcomponent.setTheMarkdown(action, record)
      }, 10)
      // this.activeMarkdown = record.content
      // this.componentKey++
    },
    async getAllData() {
      var res = await lou.customUrlGet('doc', false, true, true)
      if (res) {
        this.datatable = res.data
        this.generatePageList()
        var docdata = res.data
        console.log('docdata', docdata)
        var documentData = {
          mykey: 'allDocumentationFiles' + this.settings.whatapp.appName + this.settings.whatapp.id,
          data: JSON.stringify(docdata),
        }
        lou.addDataToDb(documentData)
        // var state = this.$store.state
        // var nindexeddb = await lou.getDataFromDb()
        // var docIndexTarget = nindexeddb.findIndex(
        //   (x) =>
        //     x.mykey ===
        //     'allDocumentationFiles' +
        //       state.settings.whatapp.appName +
        //       state.settings.whatapp.id,
        // )
        // console.log('nindexeddb', nindexeddb)
        // console.log('nindexeddb[docIndexTarget].data', nindexeddb[docIndexTarget].data)
      }
    },
    onSelectTree(selectedKeys, info) {
      var thekey = selectedKeys[0]
      // console.log('selected', selectedKeys, info)
      // console.log('thekey', thekey)
      var targetIndex = this.pageList.findIndex((x) => x.key === thekey)
      // console.log('this.pageList[targetIndex]', this.pageList[targetIndex])
      if (targetIndex >= 0) {
        this.setMarkdown('view', this.pageList[targetIndex])
      }
    },
    generatePageList() {
      this.pageList = []
      for (let i = 0; i < this.datatable.length; i++) {
        const element = this.datatable[i]
        var treed = {
          title: element.title,
          key: '0-0-' + i,
          content: element.content,
        }
        this.pageList.push(treed)
      }
    },
    deleteDocumentation(showmodal = true, record) {
      if (showmodal) {
        this.modalDelete.visible = true
        this.modalDelete.captha = ''
        this.modalDelete.data = record
      } else {
        if (
          this.modalDelete.captha ===
          'Documentation/' + this.modalDelete.data.title
        ) {
          var res = lou.customUrlDelete('doc?id=' + record.id, true, true)
          if (res) {
            this.modalDelete.captha = ''
            this.modalDelete.visible = false
            this.getAllData()
          }
        } else {
          lou.shownotif('Failed', "Text doesn't match", 'error')
        }
      }
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
  },
}
</script>

<style lang="scss" scoped></style>
